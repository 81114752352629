<template>
  <div class="container-md col-lg-11 col-md-11 pb-5 mb-2">
    <div>
      <CRow class="pr-0 pl-0">
        <CCol md="12" sm="12" lg="12">
          <h4 class="text-primary main-title mt-2 mb-4">Theme and Logo</h4>
        </CCol>
      </CRow>
    </div>
    <div>
      <CCard>
        <CCardBody>
          <CTabs
            variant="tabs"
            :active-tab="activeTab"
            @update:activeTab="updateTab"
          >
            <CTab title="Facility Logo" :disabled="isImgUpdated">
              <UploadOrgPictures
                v-if="activeTab == 0"
                :document_type_id="17"
                :isImgUpdated="isImgUpdated"
                @imgUpdated="updateValueChange"
              />
            </CTab>
            <CTab title="Job Banner" :disabled="isImgUpdated">
              <UploadOrgPictures
                v-if="activeTab == 1"
                :document_type_id="19"
                :isImgUpdated="isImgUpdated"
                @imgUpdated="updateValueChange"
              />
            </CTab>
            <CTab title="Theme & Logo" :disabled="isImgUpdated">
              <OrgConfig />
            </CTab>
          </CTabs>
        </CCardBody>
      </CCard>
    </div>
    <div class="row text-center mx-0 my-3 action-area">
      <div class="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
        <CButton
          type="button"
          name="registration-home"
          class="btn btn-outline-primary rounded-0 px-5 mx-2 btn-lg"
          @click="navToCandidateList"
        >
          Home
        </CButton>
        <CButton
          class="btn btn-primary rounded-0 px-5 mx-3 btn-lg"
          @click="goingBack()"
        >
          Back
        </CButton>
      </div>
    </div>
  </div>
</template>
<script>
import UploadOrgPictures from "@/containers/UploadOrgLogos/UploadOrgPictures";
import OrgConfig from "@/containers/OrgConfiguration/OrgConfig.vue";
import { mapGetters } from "vuex";
export default {
  name: "UploadOrganisationPictures",
  components: {
    UploadOrgPictures,
    OrgConfig,
  },
  data() {
    return {
      activeTab: 0,
      isImgUpdated: false,
    };
  },
  computed: {
    ...mapGetters(["getOrgID"]),
  },
  methods: {
    updateTab(value) {
      this.activeTab = value;
    },
    updateValueChange(value) {
      this.isImgUpdated = value;
    },
    navToCandidateList() {
      this.$router.push({
        path: "/candidate-list?active=%5Btrue,null%5D&deleted=%5Bfalse%5D",
      });
    },
    goingBack() {
      this.$router.go(-1);
    },
  },
};
</script>
