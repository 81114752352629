<template>
  <div>
    <sidebar
      class="org-config"
      :show="sideBarShow"
      :toggleSideBar="toggleSideBar"
      :positionLeft="getPosition"
    >
      <template #header>
        <CRow>
          <CCol md="12" sm="12" lg="12">
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="p-4">Edit</h5>
            </div>
          </CCol>
        </CRow>
      </template>
      <CListGroup>
        <CRow class="p-4">
          <CCol md="12" sm="12" lg="12">
            <ValidationObserver ref="uploadform" v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)" name="uploadform">
                <CRow class="row mb-3">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <label class="required font-weight-bolder">
                      Organisation
                    </label>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="organisation"
                        :value="orgs.organisation"
                        :options="
                          options && options['organisation']
                            ? options['organisation']
                            : []
                        "
                        :error="errors[0]"
                        :multiple="false"
                        :clearable="false"
                        :disabled="true"
                        @input="handleChangeOrgSelect"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
                <CCol md="12" sm="12" lg="12" v-if="isShowEdit">
                  <CRow class="row mb-4">
                    <div class="w-100">
                      <label class="required font-weight-bolder"> Type </label>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="assets_type"
                          :value="org_static_assets.assets_type"
                          :options="
                            options && options['org_static_assets']
                              ? options['org_static_assets']
                              : []
                          "
                          :error="errors[0]"
                          :multiple="false"
                          :clearable="false"
                          :disabled="true"
                          @input="handleChangeEmailSelect"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <CRow
                    class="row mb-3"
                    v-if="
                      org_static_assets.assets_type.code == 'header_logo_url'
                    "
                  >
                    <div class="w-100">
                      <label class="font-weight-bolder">
                        Organisation URL
                      </label>
                      <ValidationProvider rules="url" v-slot="{ errors }">
                        <TextInput
                          name="orgUrl"
                          :value="payload.orgUrl"
                          @input="handleUrl"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <CRow class="row mb-3" v-if="checkFileUpload">
                    <div class="w-100">
                      <div class="d-flex justify-content-center">
                        <button
                          type="button"
                          @click="deleteFile(org_static_assets.assets_type)"
                          class="box-item text-center p-2 m-2 border rounded"
                        >
                          <div class="button-icon mb-2">
                            <i class="fa-solid fa-trash-can"></i>
                          </div>
                          <div class="col-sm-12">
                            <p class="m-0">Delete</p>
                          </div>
                        </button>
                        <button
                          type="button"
                          :disabled="isCropEnable"
                          @click="openCropModal(), toggleSideBar()"
                          :class="{ 'box-item': !isCropEnable }"
                          class="text-center p-2 m-2 border rounded"
                        >
                          <div class="button-icon mb-2">
                            <i class="fa-solid fa-crop"></i>
                          </div>
                          <div class="col-sm-12">
                            <p class="m-0">Crop</p>
                          </div>
                        </button>
                      </div>
                    </div>
                  </CRow>
                  <CRow class="row mb-4" v-else>
                    <div class="w-100">
                      <div
                        class="d-flex justify-content-start mb-4 font-weight-bolder"
                        style="font-size: 12px"
                        v-if="checkFileExist"
                      >
                        <div class="mr-2">
                          <p class="m-0">{{ getFileName }}</p>
                        </div>
                        <div>
                          <CButton
                            class="btn-ghost p-0"
                            @click="deleteFile(org_static_assets.assets_type)"
                          >
                            <i class="fas fa-trash-can cursor-pointer"></i>
                          </CButton>
                        </div>
                      </div>
                      <input
                        id="upload-image"
                        name="upload-image"
                        type="file"
                        ref="fileref"
                        :accept="fileType"
                        @change="
                          handleFile(org_static_assets.assets_type, $event)
                        "
                        :disabled="
                          !orgs.organisation || !org_static_assets.assets_type
                        "
                      />
                      <div class="mt-3">
                        <div class="m-0" style="font-size: 10px">
                          <strong class="text-black">Note :</strong>
                          <ul>
                            <li>Image size should be {{ getPixelSize }} px</li>
                            <li>Extension allowed png type only</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="12" sm="12" lg="12" v-else>
                  <CRow class="row mb-4">
                    <div class="w-100">
                      <label class="required font-weight-bolder">
                        Themes
                      </label>
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="theme_style"
                          :value="theme.theme_style"
                          :options="
                            options && options['theme'] ? options['theme'] : []
                          "
                          :error="errors[0]"
                          :multiple="false"
                          :clearable="false"
                          @input="handleChangeTheme"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="w-50">
                      <ul
                        class="p-2 mt-3 d-flex"
                        style="list-style-type: none"
                        v-for="(item, index) in theme"
                        :key="index"
                      >
                        <li
                          :style="`background-color:${item.primary_color};`"
                          class="p-2"
                        ></li>
                        <li
                          :style="`background-color: ${item.secondary_color};`"
                          class="p-2"
                        ></li>
                      </ul>
                    </div>
                  </CRow>
                </CCol>
                <CRow class="row mb-3">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="d-flex justify-content-end">
                      <CButton
                        type="button"
                        color="primary"
                        variant="outline"
                        class="btn btn-outline-primary mx-2"
                        @click="
                          ((payload[org_static_assets.assets_type.code] = null),
                          (cropperModal.file = null)),
                            toggleSideBar()
                        "
                        :disabled="isSubmitting"
                        >Cancel</CButton
                      >
                      <CButton
                        v-if="isShowEdit"
                        type="button"
                        color="primary"
                        class="btn btn-primary mx-2"
                        @click="onSubmit()"
                        :disabled="isSubmitting"
                        >Upload</CButton
                      >
                      <CButton
                        v-else
                        type="button"
                        color="primary"
                        class="btn btn-primary mx-2"
                        @click="updateThemeStyle()"
                        :disabled="isSubmitting"
                        >Update</CButton
                      >
                    </div>
                  </div>
                </CRow>
              </form>
            </ValidationObserver>
          </CCol>
        </CRow>
      </CListGroup>
    </sidebar>
    <CCard>
      <CCardBody class="m-4">
        <CRow class="justify-content-between">
          <CCol md="12" sm="12" lg="12" class="border rounded preview-box">
            <span
              v-if="getWindowWidth > 490"
              class="header-edit edit rounded-circle"
              :style="{ left: windowWidth > 420 ? '14%' : '40%' }"
              @click="toggleSideBar(), editorSelect('header_logo_url')"
              v-c-tooltip="{
                content: 'Update Header Logo and Organisation URL',
                placement: 'top',
              }"
            >
              <i class="fa-solid fa-pen-to-square"></i>
            </span>
            <span
              v-if="getWindowWidth > 490"
              class="login-edit edit rounded-circle"
              @click="toggleSideBar(), editorSelect('login_logo_url')"
              v-c-tooltip="{
                content: 'Update Login Logo',
                placement: 'top',
              }"
            >
              <i class="fa-solid fa-pen-to-square"></i>
            </span>
            <span
              v-if="getWindowWidth > 490"
              class="custom-edit edit rounded-circle"
              @click="toggleSideBar(), editorSelect('customer_logo_url')"
              v-c-tooltip="{
                content: 'Update Customer Logo',
                placement: 'top',
              }"
            >
              <i class="fa-solid fa-pen-to-square"></i>
            </span>
            <span
              v-if="getWindowWidth > 490"
              class="logo-edit-bg edit rounded-circle"
              @click="toggleSideBar(), editorSelect('banner_path_url')"
              v-c-tooltip="{
                content: 'Update Landing Page Image',
                placement: 'top',
              }"
            >
              <i class="fa-solid fa-pen-to-square"></i>
            </span>
            <span
              v-if="getWindowWidth > 490"
              class="theme-edit edit rounded-circle"
              @click="
                toggleSideBar(),
                  editorSelect('theme_style'),
                  (isShowEdit = false)
              "
              v-c-tooltip="{
                content: 'Update Product Theme',
                placement: 'top',
              }"
            >
              <i class="fa-solid fa-pen-to-square"></i>
            </span>
            <CRow class="row m-0 p-0 border" v-if="windowWidth > 420">
              <div class="app w-100">
                <div class="header">
                  <div>
                    <img
                      id="header_img"
                      :src="header"
                      @error="$event.target.src = app"
                      style="width: 100%; height: auto"
                      alt="header"
                    />
                  </div>
                  <div>
                    <img
                      id="header_logo_url"
                      :src="header_preview"
                      @error="$event.target.src = default_image"
                      style="
                        position: absolute;
                        left: 2%;
                        top: 10%;
                        width: 8%;
                        height: auto;
                        z-index: 11;
                        max-height: 40px;
                      "
                      class="p-1"
                      alt="logo"
                    />
                  </div>
                </div>
                <div
                  class="d-flex w-100"
                  :class="
                    windowWidth > 1000
                      ? 'justify-content-between'
                      : 'justify-content-center'
                  "
                >
                  <div class="body"></div>
                  <div class="body py-3 pr-5">
                    <div class="login-form">
                      <img
                        id="body_img"
                        :src="body"
                        @error="$event.target.src = app"
                        style="width: 100%; height: auto; max-height: 387px"
                        alt="body"
                      />
                    </div>
                    <div class="text-center">
                      <img
                        id="customer_logo_url"
                        :src="customer_logo_preview"
                        @error="$event.target.src = custom_image"
                        style="
                          position: absolute;
                          right: 30%;
                          top: 31.5%;
                          z-index: 11;
                          width: 30%;
                          height: auto;
                        "
                        alt="logo"
                      />
                    </div>
                    <div v-if="windowWidth > 1000" class="text-center">
                      <img
                        id="login_logo_url"
                        :src="login_preview"
                        @error="$event.target.src = default_image"
                        style="
                          width: 312px;
                          width: 50%;
                          height: auto;
                          position: absolute;
                          right: 30%;
                          top: 10%;
                          z-index: 11;
                        "
                        alt="logo"
                      />
                    </div>
                  </div>
                </div>
                <div class="footer w-100">
                  <img
                    id="footer_img"
                    :src="Layer_3"
                    @error="$event.target.src = app"
                    style="width: 100%; height: auto"
                    alt="footer"
                  />
                </div>
              </div>
              <div class="app-assets">
                <div
                  class="col-sm-12 col-md-12 col-lg-12 px-4 m-0"
                  style="
                    position: absolute;
                    z-index: 0;
                    top: 12%;
                    left: 0%;
                    width: 100%;
                    height: auto;
                  "
                >
                  <img
                    v-if="windowWidth > 1000"
                    id="banner_path_url"
                    :src="banner_preview"
                    @error="$event.target.src = bg_image"
                    :style="{
                      width: banner_preview.includes('new_home_bg.png')
                        ? 'auto'
                        : '100%',
                    }"
                    style="max-height: 430px; max-width: 1040px"
                    alt="logo"
                  />
                </div>
              </div>
            </CRow>
            <CRow class="row m-0 p-0 border" v-else>
              <div class="app">
                <div>
                  <img
                    id="header_img"
                    :src="app"
                    @error="$event.target.src = app"
                    style="width: 100%; height: auto"
                    alt="header"
                  />
                </div>
                <div>
                  <img
                    id="header_logo_url"
                    :src="header_preview"
                    @error="$event.target.src = default_image"
                    style="
                      position: absolute;
                      left: 12%;
                      top: 4%;
                      width: 25%;
                      height: auto;
                      z-index: 11;
                      max-height: 40px;
                    "
                    class="p-1"
                    alt="logo"
                  />
                </div>
                <div>
                  <img
                    id="customer_logo_url"
                    :src="customer_logo_preview"
                    @error="$event.target.src = custom_image"
                    style="
                      position: absolute;
                      right: 36%;
                      top: 21%;
                      z-index: 11;
                      width: 16%;
                      height: auto;
                    "
                    alt="logo"
                  />
                </div>
              </div>
            </CRow>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CropperModal
      v-if="cropperModal.isShowPopup"
      :aspectRatio="2"
      :isShowPopup="cropperModal.isShowPopup"
      :fileData="cropperModal.file"
      @modalCallBack="cropperModalCallBack"
    />
  </div>
</template>
<script>
import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { isObject, isEmptyObjectCheck, generateLocalFileUrl } from "@/helpers/helper";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

import Select from "@/components/reusable/Fields/Select.vue";
import sidebar from "@/components/reusable/GenericFilterAndSearch/sidebar.vue";
import CropperModal from "@/components/reusable/CropperModal";
import TextInput from "@/components/reusable/Fields/TextInput";

extend("required", { ...required, message: "This field is required" });
extend("url", (value) => {
  const urlRegex =
    "((http|https)://)(www.)?" +
    "[a-zA-Z0-9@:%._\\+~#?&//=]" +
    "{2,256}\\.[a-z]" +
    "{2,6}\\b([-a-zA-Z0-9@:%" +
    "._\\+~#?&//=]*)";
  if (!value.match(urlRegex)) {
    return "please Enter valid URL with http://";
  }
  return true;
});

import Layer_2 from "../../../public/img/preview/Layer_2.png";
import Layer_3 from "../../../public/img/preview/Layer_3.png";
import Layer_5 from "../../../public/img/preview/Layer_5.png";
import Layer_7 from "../../../public/img/preview/Layer_7.png";
import Layer_8 from "../../../public/img/preview/Layer_8.png";
import Layer_9 from "../../../public/img/preview/Layer_9.png";
import Layer_10 from "../../../public/img/preview/Layer_10.png";
import Layer_11 from "../../../public/img/preview/Layer_11.png";
import Layer_12 from "../../../public/img/preview/Layer_12.png";
import Layer_13 from "../../../public/img/preview/Layer_13.png";
import Layer_15 from "../../../public/img/preview/Layer_15.png";
import Layer_16 from "../../../public/img/preview/Layer_16.png";

export default {
  components: {
    Select,
    sidebar,
    CropperModal,
    TextInput,
  },
  data() {
    return {
      Layer_3,
      Layer_7,
      Layer_2,
      Layer_5,
      Layer_8,
      Layer_9,
      Layer_10,
      Layer_11,
      Layer_12,
      Layer_13,
      Layer_15,
      Layer_16,
      bg_image: "img/new_home_bg.png",
      default_image: "/img/new_tf_logo.svg",
      custom_image: "/img/talentfind_logo.png",
      orgs: {},
      org_static_assets: {},
      theme: {},
      payload: {},
      fileType: "image/png",
      isSubmitting: false,
      sideBarShow: false,
      isShowEdit: false,
      cropperModal: {
        isShowPopup: false,
        file: null,
        buttonLabel: "Apply",
      },
      orgUrl: null,
      generateLocalFileUrl
    };
  },
  watch: {},
  computed: {
    ...mapGetters([
      "getOrgConfigThemeFiles",
      "getOrgStaticAssestsEnum",
      "allCustomerOrganisation",
      "getWindowWidth",
      "getPathAcronymFromAccessToken",
    ]),
    allOrganisationListOpt() {
      return (
        this.allCustomerOrganisation.map((v) => ({
          code: v?.organisation_id,
          label: v?.organisation?.name,
          customer_id: v?.customer_uid,
          path_acronym: v?.path_acronym,
          theme_style: v?.organisation?.theme_style,
          organisation_id: v?.organisation_id,
          wedsite_url: v?.organisation?.website,
        })) || []
      );
    },
    emailStaticAssetsOpt() {
      return this.getOrgStaticAssestsEnum;
    },
    getThemeStyle() {
      return [
        {
          label: "Theme Default",
          code: null,
          primary_color: "#dd3651",
          secondary_color: "#b8b8b8",
        },
        {
          label: "Theme Dark Cyan",
          code: "theme_dark_cyan",
          primary_color: "#0094a8",
          secondary_color: "#d9f0f3",
        },
        {
          label: "Theme Blue",
          code: "theme_blue",
          primary_color: "#2daae1",
          secondary_color: "#f0f0f0",
        },
        {
          label: "Theme TFW",
          code: "theme_tfw",
          primary_color: "#cb126b",
          secondary_color: "#fde6f2",
        },
        {
          code: "theme_dark_blue",
          label: "Theme Dark Blue",
          primary_color: "#006ec7",
          secondary_color: "#dae1f3",
        },
        {
          code: "theme_cadmium_orange",
          label: "Theme Cadmium Orange",
          primary_color: "#f58020",
          secondary_color: "#fadbc2",
        },
        {
          code: "theme_science_blue",
          label: "Theme Science Blue",
          primary_color: "#006ec7",
          secondary_color: "#b3d9f8",
        },
      ];
    },
    options() {
      return {
        organisation: this.allOrganisationListOpt,
        org_static_assets: this.getOrgStaticAssestsEnum,
        theme: this.getThemeStyle,
      };
    },
    checkBlobExist() {
      if (this.org_static_assets.assets_type) {
        if (
          this.getOrgConfigThemeFiles[
            this.org_static_assets.assets_type.code
          ] ||
          this.payload[this.org_static_assets.assets_type.code]
        ) {
          return false;
        }
      }
      return true;
    },
    checkFileExist() {
      if (this.org_static_assets.assets_type) {
        if (
          this.getOrgConfigThemeFiles[this.org_static_assets.assets_type.code]
        ) {
          return true;
        }
      }
      return false;
    },
    checkFileUpload() {
      if (this.org_static_assets.assets_type) {
        if (this.payload[this.org_static_assets.assets_type.code]) {
          return true;
        }
      }
      return false;
    },
    isCropEnable() {
      if (this.org_static_assets.assets_type) {
        if (this.payload[this.org_static_assets.assets_type.code]) {
          return false;
        }
      }
      return true;
    },
    getPosition() {
      if (this.org_static_assets.assets_type) {
        if (this.org_static_assets.assets_type.code == "header_logo_url") {
          return false;
        }
      }
      return true;
    },
    getFileName() {
      if (this.org_static_assets.assets_type) {
        if (
          this.getOrgConfigThemeFiles[this.org_static_assets.assets_type.code]
        ) {
          return this.getOrgConfigThemeFiles[
            this.org_static_assets.assets_type.code
          ].split("images/")[1];
        } else if (this.payload[this.org_static_assets.assets_type.code]) {
          return this.payload[this.org_static_assets.assets_type.code].name;
        }
      }
      return "untitled.png";
    },
    getPixelSize() {
      if (this.org_static_assets.assets_type) {
        if (this.org_static_assets.assets_type.code == "customer_logo_url") {
          return "700 x 200";
        } else if (
          this.org_static_assets.assets_type.code == "banner_path_url"
        ) {
          return "1349 x 756";
        } else if (
          this.org_static_assets.assets_type.code == "login_logo_url"
        ) {
          return "769 x 312";
        } else if (
          this.org_static_assets.assets_type.code == "header_logo_url"
        ) {
          return "700 x 300";
        }
      }
    },
    header_preview() {
      if (this.payload["header_logo_url"]) {
        return URL.createObjectURL(this.payload["header_logo_url"]);
      } else if (this.getOrgConfigThemeFiles["header_logo_url"]) {
        // ---------- FOR LOCAL -------
        return generateLocalFileUrl(this.getOrgConfigThemeFiles["header_logo_url"]);

        // ---------- FOR AZURE -------
        // return `${
        //   this.getOrgConfigThemeFiles["header_logo_url"]
        // }?${new Date().getTime()}`;
      }
      return this.default_image;
    },
    login_preview() {
      if (this.payload["login_logo_url"]) {
        return URL.createObjectURL(this.payload["login_logo_url"]);
      } else if (this.getOrgConfigThemeFiles["login_logo_url"]) {
        // ---------- FOR LOCAL -------
        return generateLocalFileUrl(this.getOrgConfigThemeFiles["login_logo_url"]);

        // ---------- FOR AZURE -------
        // return `${
        //   this.getOrgConfigThemeFiles["login_logo_url"]
        // }?${new Date().getTime()}`;
      }
      return this.default_image;
    },
    banner_preview() {
      if (this.payload["banner_path_url"]) {
        return URL.createObjectURL(this.payload["banner_path_url"]);
      } else if (this.getOrgConfigThemeFiles["banner_path_url"]) {
        // ---------- FOR LOCAL -------
        return generateLocalFileUrl(this.getOrgConfigThemeFiles["banner_path_url"]);

        // ---------- FOR AZURE -------
        // return `${
        //   this.getOrgConfigThemeFiles["banner_path_url"]
        // }?${new Date().getTime()}`;
      }
      return this.bg_image;
    },
    customer_logo_preview() {
      if (this.payload["customer_logo_url"]) {
        return URL.createObjectURL(this.payload["customer_logo_url"]);
      } else if (this.getOrgConfigThemeFiles["customer_logo_url"]) {
        // ---------- FOR LOCAL -------
        return generateLocalFileUrl(this.getOrgConfigThemeFiles["customer_logo_url"]);

        // ---------- FOR AZURE -------
        // return `${
        //   this.getOrgConfigThemeFiles["customer_logo_url"]
        // }?${new Date().getTime()}`;
      }
      return this.custom_image;
    },
    header() {
      if (this.theme.theme_style) {
        if (this.theme.theme_style.code == "theme_dark_cyan") {
          return Layer_5;
        } else if (this.theme.theme_style.code == "theme_blue") {
          return Layer_9;
        } else if (this.theme.theme_style.code == "theme_tfw") {
          return Layer_15;
        }
      }
      return Layer_2;
    },
    body() {
      if (this.theme.theme_style) {
        if (this.theme.theme_style.code == "theme_dark_cyan") {
          return Layer_8;
        } else if (this.theme.theme_style.code == "theme_blue") {
          return Layer_10;
        } else if (this.theme.theme_style.code == "theme_tfw") {
          return Layer_16;
        }
      }
      return Layer_7;
    },
    app() {
      if (this.theme.theme_style) {
        if (this.theme.theme_style.code == "theme_dark_cyan") {
          return Layer_13;
        } else if (this.theme.theme_style.code == "theme_blue") {
          return Layer_12;
        }
      }
      return Layer_11;
    },
    windowWidth() {
      return this.getWindowWidth;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "uploadConfigThemeFiles",
      "deleteConfigThemeFilesByAcronym",
      "updateOrganisation",
      "fetchExistingConfigThemeFiles",
      "fetchAllCustomerOrganisation",
      "fetchOrgStaticAssetsEnum",
      "updateOrgThemeManually",
      "fetchOrgDetailsByAcronym"
    ]),
    handleChangeOrgSelect(name, value) {
      Vue.set(this.orgs, name, value);
      this.fetchExistingConfigThemeFiles(value.path_acronym).then((res) => {
        if (this.getOrgStaticAssestsEnum.length) {
          this.handleChangeEmailSelect(
            "assets_type",
            this.getOrgStaticAssestsEnum[0]
          );
          this.payload.orgUrl = this.allOrganisationListOpt[0].wedsite_url;
        }
      });
      this.payload = {};
    },
    handleChangeEmailSelect(name, value) {
      Vue.set(this.org_static_assets, name, value);
    },
    handleChangeTheme(name, value) {
      Vue.set(this.theme, name, value);
      Vue.set(this.payload, name, value);
    },
    handleUrl(name, value) {
      Vue.set(this.payload, name, value);
    },
    handleFile(type, event) {
      const file = event.target.files[0];
      const fileExt = this.getExtension(file.name);
      if (this.fileType.includes(fileExt)) {
        if (file.type.startsWith("image/")) {
          Vue.set(this.payload, type.code, file);
        }
      } else {
        this.showToast({
          class: "bg-danger text-white",
          message: "File extension not allowed !",
        });
      }
      this.$refs.fileref.value = null;
    },
    openCropModal() {
      this.cropperModal.isShowPopup = true;
      this.cropperModal.file =
        this.payload[this.org_static_assets.assets_type.code];
    },
    cropperModalCallBack(action, data) {
      if (action === false) {
        this.cropperModal.isShowPopup = false;
      } else {
        this.cropperModal.isShowPopup = false;
        this.payload[this.org_static_assets.assets_type.code] = data.fileBlob;
      }
      this.handleChangeEmailSelect(
        "asset_type",
        this.org_static_assets.assets_type
      );
      this.toggleSideBar();
    },
    async onSubmit() {
      const isValid = await this.$refs.uploadform.validate();
      try {
        if (
          this.payload.orgUrl &&
          this.org_static_assets.assets_type.code == "header_logo_url"
        ) {
          let finalPayload = {
            organisation_id: this.orgs.organisation.organisation_id,
            website: this.payload.orgUrl,
          };
          await this.updateOrganisation(finalPayload).then(() => {
            this.showToast({
              class: "bg-success text-white",
              message: "URL updated successfully",
              position: "bottom-left",
            });
            delete this.payload.orgUrl;
          });
          if (!this.checkFileUpload) return;
        }

        if (!isValid || !this.checkFileUpload) {
          this.showToast({
            class: "bg-danger text-white",
            message: !this.checkFileUpload
              ? "please upload your image !"
              : "please fill mandatory fields!",
          });
          return;
        }
        this.isSubmitting = true;
        let finalPayload = {
          ...this.payload,
          acronym: this.orgs.organisation.path_acronym,
        };
        await this.uploadConfigThemeFiles(finalPayload).then(async (res) => {
          await this.fetchExistingConfigThemeFiles(finalPayload.acronym).then((res) => {
            this.payload = {};
            this.isSubmitting = false;
            this.cropperModal.file = null;
          });
        });
      } catch(err) {
        console.error(err)
      } finally {
        this.fetchOrgDetailsByAcronym(this.getPathAcronymFromAccessToken)
      }
    },
    updateThemeStyle() {
      this.isSubmitting = true;
      let finalPayload = {
        organisation_id: this.orgs.organisation.organisation_id,
        theme_style:
          this.payload.theme_style.code == "theme_default"
            ? null
            : this.payload.theme_style.code,
      };
      this.updateOrganisation(finalPayload).then((res) => {
        this.payload = {};
        this.isSubmitting = false;
      });
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return _.lowerCase(parts[parts.length - 1]);
    },
    deleteFile(type) {
      if (this.payload[type.code]) {
        this.payload[type.code] = null;
      } else {
        let payload = {
          acronym: this.orgs.organisation.path_acronym,
          documentName: `delete_${
            type.code == "banner_path_url"
              ? type.code.replace("_path_url", "")
              : type.code.replace("_url", "")
          }`,
        };
        this.deleteConfigThemeFilesByAcronym(payload).then((res) => {
          this.getOrgConfigThemeFiles[type.code] = null;
        });
      }
    },
    toggleSideBar() {
      this.sideBarShow = !this.sideBarShow;
    },
    editorSelect(val) {
      this.handleChangeEmailSelect(
        "assets_type",
        val == "theme_style"
          ? { code: "theme_style", label: "Theme Style" }
          : this.getOrgStaticAssestsEnum.find((obj) => {
              return obj.code === val;
            })
      );
      this.isShowEdit = true;
    },
  },
  mounted() {
    this.fetchAllCustomerOrganisation().then((res) => {
      if (this.allOrganisationListOpt.length) {
        this.handleChangeOrgSelect(
          "organisation",
          this.allOrganisationListOpt[0]
        );
        this.handleChangeTheme(
          "theme_style",
          this.getThemeStyle.find((obj) => {
            return obj.code === this.allOrganisationListOpt[0].theme_style;
          })
        );
        this.orgUrl = this.allOrganisationListOpt[0].wedsite_url;
      }
    });
    this.fetchOrgStaticAssetsEnum().then((res) => {
      if (this.getOrgStaticAssestsEnum.length) {
        this.handleChangeEmailSelect(
          "assets_type",
          this.getOrgStaticAssestsEnum[0]
        );
      }
    });
  },
  created() {
    window.addEventListener("resize", () => {
      this.$store.commit("SET_WINDOW_WIDTH");
    });
  },
};
</script>

<style lang="scss" scoped>
.preview-box {
  padding: 22px 24px 20px;
  background-color: var(--modal-background);
  border-radius: 3px;
  box-shadow: 0 17px 50px 0 rgba(var(--shadow-rgb), 0.19),
    0 12px 15px 0 rgba(var(--shadow-rgb), 0.24);
}

.hightlight {
  border: 1px solid;
  border-color: #ff6565 !important;
}
.edit {
  background-color: #2196f3;
  color: #f5f5f5;
  padding: 6px 8px;
  font-size: 10px;
  cursor: pointer;
}

.header-edit {
  position: absolute;
  left: 14%;
  top: 3%;
  z-index: 11;
}

.login-edit {
  position: absolute;
  right: 8%;
  top: 20%;
  z-index: 11;
}

.custom-edit {
  position: absolute;
  right: 8%;
  top: 31%;
  z-index: 11;
}

.logo-edit-bg {
  position: absolute;
  right: 1%;
  top: 10%;
  z-index: 11;
}

.theme-edit {
  position: absolute;
  right: -1rem;
  top: -1rem;
  z-index: 11;
}

.header,
.body,
.footer {
  position: relative;
  z-index: 9;
}

.body {
  max-height: 519px !important;
  vertical-align: center;
}

.box-item {
  color: #2f4f4f;
  background-color: #f5f5f5;
  cursor: pointer;
}

.box-item:hover {
  box-shadow: 0 0px 10px 0 rgba(var(--shadow-rgb), 0.19),
    0 0px 10px 0 rgba(var(--shadow-rgb), 0.24);
}
</style>
